.App {
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.timer {
  display: grid;
  grid-template-columns: 80px  auto  auto  30px;
  margin: 15px 0;
  align-items: center;
}

.timer > span {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  display: inline-block;
  min-width: 30px;
  padding: 0 10px;
}

button {
  padding: 7px;
  border: 0;
  background-color: #888;
  border-radius: 5px;
  font-size: 1.1rem;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
}

.start {
  background-color: #6f6;
  color: #000;
}

.stop {
  background-color: #f66;
  color: #000;
}

input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #888;
  font-size: 1.3rem;
  min-width: 30px;
  height: 36px;
}
